import React from "react";
import ashleyGift from "../Images/ashley-gift.png";
function GiftBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={ashleyGift} alt="ashley-gift.png" />
      </div>

      {/* <div className="col-md-11" style={{ fontFamily: "Arial" }}>
        Once you receive your email from{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL}>
          {process.env.REACT_APP_DESC_EMAIL}
        </a>, you can use the card virtually!
      </div> */}
      <div className="col-md-11" style={{ fontFamily: "Arial" }}>
        Once you receive and action your email from{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_REWARD}>
          {process.env.REACT_APP_DESC_REWARD}
        </a>
        ,{" "}
        <a href={"mailto:" + process.env.REACT_APP_PREP}>
          {process.env.REACT_APP_PREP}
        </a>{" "}
        or{" "}
        <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
          {process.env.REACT_APP_MARKET_PLACE}
        </a>{" "}
        , you can use the reward card virtually or request a card to be mailed
        to you.
      </div>
    </div>
  );
}
export default GiftBox;
